.modal-container {
  border-radius: 20px;
  width: 550px;
  height: 512px;
  background-color: #fbfaff;
  position: fixed;
  font-family: "Montserrat";
  top: 50%;
  right: 50%;
  z-index: 1;
  transform: translate(50%, -50%);
  padding: 2rem 3rem;
  box-sizing: border-box;
}

.modal-container.creating {
  width: 800px;
  height: 600px;
}

.welcome-top-section {
  justify-content: flex-start;
  align-items: center;
}

.creating .welcome-top-section {
  margin: 0 0 0 0;
}

.welcome-bottom-section {
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}

.welcome-btn-row {
  width: 80%;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0.75rem;
}

.welcome-btn-row.join {
  margin: 1.25rem;
}

.welcome-card-row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  column-gap: 0;
  row-gap: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-items: center;
  overflow: hidden;
  overflow-y: auto;
  max-height: 290px;
  padding-bottom: 1rem;
}

.welcome-type-col {
  /* width: 80%; */
  margin-top: 1.25rem;
  justify-content: center;
  align-items: center;
}

.room-btn {
  margin: 0.5rem 0;
}

.text-description {
  color: var(--lightgray);
  font-family: "Roboto";
  font-size: 1rem;
  margin: 0.5rem;
  text-align: left;
}

.text-description:last-of-type {
  margin-bottom: 1rem;
}

.inline-link {
  color: var(--primary);
  cursor: pointer;
  text-decoration: none;
}

.overlay .inline-link {
  color: var(--secondary);
}

@media screen and (max-width: 860px) {
  .modal-container.creating {
    width: 80vw;
    height: 550px;
  }

  .welcome-card-row {
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    margin-bottom: 1.15rem;
    padding: 0.75rem 0;
  }
}

@media screen and (max-width: 680px) {
  .modal-container {
    width: 80vw;
    /* height: 60vh;  */
  }

  .text-description {
    font-size: 0.875rem;
    margin: 0.875rem;
  }

  .welcome-btn-row {
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .modal-container {
    height: auto;
  }

  .modal-container .text-description {
    text-align: center;
  }
}

@media screen and (max-width: 400px) {
  .modal-container {
    padding: 2rem;
  }
}
