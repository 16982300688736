.menu-title {
  font-family: "Montserrat", system-ui, -apple-system, "Open Sans", "Helvetica Neue", sans-serif;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  margin: 0 0 1rem 0;
}

.info-modal-text {
  font-family: "Roboto", system-ui, -apple-system, "Open Sans", "Helvetica Neue", sans-serif;
  color: #ffffff;
  font-size: 1rem;
  text-align: left;
  margin: 0 0 1rem 0;
}

.menu-box-container {
  border-radius: 8px;
  border: 1px solid var(--primarylight3);
  padding: 0.75rem;
}

.menu-box-container.margin-btm {
  margin-bottom: 1rem;
}

.menu-box-title {
  font-family: "Montserrat", system-ui, -apple-system, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  font-weight: bold;
  text-align: left;
  color: #ffffff;
}

.menu-box-title.margin-btm {
  margin-bottom: 0.25rem;
}

.menu-box-title.big {
  font-size: 1rem;
}

.menu-box-text {
  font-family: "Roboto", system-ui, -apple-system, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  text-align: left;
  color: #ffffff;
}

.menu-box-text.big {
  font-size: 1rem;
}

.menu-box-text.margin-btm {
  margin-bottom: 0.75rem;
}

.code-container {
  width: 100%;
  background-color: var(--primarydark2);
  border-radius: 4px;
  height: 2.5rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
}

.invite-code {
  font-family: "Roboto", system-ui, -apple-system, "Open Sans", "Helvetica Neue", sans-serif;
  color: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  flex-grow: 1;
  margin-left: 1.5rem;
}

.copy-icon-container {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  transition: 0.2s;
  cursor: pointer;
  position: relative;
}

.copy-icon-container:hover {
  background-color: var(--primarydark);
}


.copy-icon-container:hover .tooltip-copy {
  visibility: visible;
  opacity: 1;
}

.tooltip-copy {
  width: 2.5rem;
  right: 50%;
  top: -36px;
  transform: translateX(50%);
  position: absolute;
  background-color: var(--darkoverlay);
  visibility: hidden;
  opacity: 0;
  padding: 0.5rem;
  border-radius: 6px;
  transition: 0.2s;
  white-space: nowrap;
  z-index: 2;
  transition: background-color 0.3s ease;
}

.tooltip-copy p {
  font-family: "Roboto";
  color: #ffffff;
  font-size: 12px;
  margin: 0;
  text-align: center;
}

.triangle-copy {
  position: absolute;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid var(--darkoverlay);
  right: 50%;
  transform: rotateZ(90deg) translateY(-30%);
  bottom: -7px;
  transition: border-left-color 0.3s ease;
}

.tooltip-copy.active {
  background-color: var(--secondary);
}

.tooltip-copy.active .triangle-copy {
  border-left-color: var(--secondary);
}

.tooltip-copy.active p {
  color: var(--dark);
}

.highlight {
  color: var(--highlight);
}

.info-modal-codes {
  font-family: "Roboto", system-ui, -apple-system, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  margin: 0.3rem 0.5rem;
}

@media screen and (max-width: 680px) {
  .menu-title {
    font-size: 1.25rem;
  }
  .info-modal-text {
    font-size: 0.75rem;
  }

  .menu-box-title.big {
    font-size: 0.875rem;
  }

  .menu-box-text,
  .menu-box-title {
    font-size: 0.625rem;
  }

  .invite-code {
    font-size: 0.875rem;
  }

  .code-container {
    height: auto;
  }
}
