.el-circle.mute {
  fill: var(--error);
  transition: 0.2s;
}

.el-circle.unmute {
  fill: #ffffff;
  transition: 0.2s;
}

.el-path.mute {
  fill: #ffffff;
  stroke: #ffffff;
}

.el-path.unmute {
  fill: var(--primary);
}

.icon-anchor:hover .el-circle.mute {
  fill: var(--secondary);
}

.icon-anchor:hover .el-path.mute {
  fill: var(--primary);
  stroke: var(--primary);
}


