.btn {
  background-color: var(--secondary);
  color: var(--primary);
  padding: 6px 18px;
  border-radius: 6px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  min-width: 120px;
  height: 32px;
}

.btn:active {
  transform: scale(0.9);
}

.btn:disabled {
  transform: scale(1);
}

.btn.list {
  padding: 6px;
  font-size: 12px;
  width: 80px;
  margin-right: 4px;
}

.btn.small {
  padding: 8px 12px;
  font-size: 12px;
  /* width: 120px; */
  min-width: auto;
  height: auto;
}

.btn.big {
  font-size: 18px;
  width: 160px;
  height: 40px;
}

.btn.delete {
  background-color: var(--error);
  color: white
}

.btn.img {
  margin-left: 1rem;
}

.btn.active {
  background-color: white;
}

.btn.cancel {
  background-color: transparent;
  color: white;
}

@media screen and (max-width: 680px) {
  .btn {
    padding: 6px 12px;
    font-size: 0.8rem;
    min-width: 96px;
    height: 32px;
  }
}
