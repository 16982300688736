.audio-switch-container {
  justify-content: space-between;
  align-items: center;
}

.user-row, .elements-row {
  padding: 0.5rem;
  width: 100%;
  text-align: center;
  background-color: var(--primarydark2);
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 0.25rem;
  align-items: center;
  justify-content: space-between;
}

.elements-row:first-of-type {
  margin-top: 0.75rem;
}

.user-color {
  width: 1rem;
  height: 1rem;
  border-radius: 4px;
  border: 1px solid var(--gray);
}

.user-name {
  font-family: "Roboto";
  font-size: 1rem;
  margin-left: 0.5rem;
  color: white;
}

.audio-btns {
  margin: 0.5rem 0 0 0;
}

.btn-mgn-right {
  margin-right: 0.5rem;
}

.empty-list-msg {
  padding: 0.5rem 0;
  width: 100%;
  text-align: center;
  background-color: var(--primarydark2);
  border-radius: 4px;
  /* height: 2.5rem; */
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto";
  font-size: 1rem;
  color: white;
  margin-top: 0.5rem;
}

@media screen and (max-width: 680px) {
  .empty-list-msg {
    font-size: 0.625rem;
    text-align: center;
  }

  .user-name {
    font-size: 0.625rem;
  }
}