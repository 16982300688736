.overlay {
  background-color: rgba(23, 58, 123, 0.6);
  backdrop-filter: blur(14px);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.1s ease-in;
  opacity: 0;
  z-index: 2;
  visibility: hidden;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}

.overlay-text {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  font-weight: normal;
  text-align: left;
  text-decoration: none;
  color: #ffffff;
}

.overlay-container {
  width: 54rem;
  padding: 0.5rem 0 1.5rem 0;
}

@media screen and (max-width: 820px) {
  .overlay-container {
    width: 80vw;
    padding: 0.5rem 0;
  } 
}
@media screen and (max-width: 680px) {
  .overlay-text {
    font-size: 0.875rem;
  }
}
