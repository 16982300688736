.side-menu {
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 50%;
  right: 1.25rem;
  transform: translateY(-50%);
  z-index: 2;
  background-color: var(--primarylight);
  padding: 1rem 0.75rem;
  border: 1px solid var(--primarylight);
  border-radius: 38px;
}

@media (max-width: 440px) {
  .side-menu {
    padding: 0.75rem 0.5rem;
    right: 0.5rem;
  }
}