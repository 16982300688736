.icon-container {
  position: relative;
}

.element-icon {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
}

.icon-anchor {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-container {
  width: 50px;
  height: 50px;
}

.el-circle {
  fill: #ffffff;
  transition: 0.2s;
}

.el-path {
  fill: var(--primarydark);
}

.icon-anchor:hover .el-circle {
  fill: var(--secondary);
}

.el-circle.active {
  fill: var(--secondary);
}

.icon-anchor:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip {
  right: calc(100% + 22px);
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background-color: var(--darkoverlay);
  visibility: hidden;
  opacity: 0;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  transition: 0.2s;
  white-space: nowrap;
}

.tooltip p {
  font-family: "Roboto";
  color: #ffffff;
  font-size: 1rem;
  text-align: left;
  margin: 0;
}

.triangle {
  position: absolute;
  top: 50%;
  right: -10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--darkoverlay);
  transform: translateY(-50%);
}

.hide {
  display: none;
}

@media screen and (max-width: 900px) {
  .icon-anchor:hover .tooltip {
    display: none;
  }
}

@media (max-width: 440px) {
  .svg-container {
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-height: 450px) and (orientation: landscape) {
  .svg-container {
    width: 28px;
    height: 26.5px;
  }
}
