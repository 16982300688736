.bottom-menu-container {
  position: fixed;
  bottom: -1.8rem;
  right: 50%;
  z-index: 1;
  transform: translate(50%, -50%);
  z-index: 1;
  background-color: var(--primarylight);
  border-radius: 38px;
  border: 1px solid var(--primarylight);
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.bottom-menu-container .icon-margin-right {
  margin-right: 0.75rem;
}

@media (max-width: 440px) {
  .bottom-menu-container {
    padding: 0.75rem 0.5rem;
  }
  
  .bottom-menu-container {
    right: 0.5rem;
    bottom: 1rem;
    transform: none;
  }
}