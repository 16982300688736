/* Color palette */
:root {
  --primary: #5c45eb;
  --primarydark: #4432b0;
  --primarydark2: rgba(72, 54, 184, 0.7);
  --primarydark3: #3E2E9E;
  --primarylight: rgba(92, 69, 235, 0.8);
  --primarylight2: rgba(92, 69, 235, 0.9);
  --primarylight3: rgb(145, 128, 255);
  --secondary: #9bf99f;
  --highlight: #FCE25C;
  --gray: rgba(242, 242, 242);
  --lightgray: #6b707a;
  --lightergray: rgba(241, 239, 255, 0.25);
  --darkoverlay: rgb(26, 28, 34, 0.9);
  --dark: #231F20;
  --error: rgba(255, 42, 0);
}

/* Fonts */
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-display: swap;
  src: local('Roboto'), local('Roboto-Regular'), url("./assets/fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  font-display: swap;
  src: local('Roboto Medium'), local('Roboto-Medium'), url("./assets/fonts/Roboto-Medium.ttf");
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  font-display: swap;
  src: local('Roboto Bold'), local('Roboto-Bold'), url("./assets/fonts/Roboto-Bold.ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat'), local('Montserrat-Regular'), url("./assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url("./assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  font-display: swap;
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url("./assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url("./assets/fonts/Montserrat-Bold.ttf");
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
}

button {
  cursor: pointer;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.list-container {
  margin-top: 0.5rem;
}

.scroll-list {
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
}

.list-title {
  font-family: "Roboto", system-ui, -apple-system, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.25rem;
}

.list-row {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.75rem;
}

.switch-row {
  align-items: center;
  justify-content: flex-start;
  margin: 1.25rem 0 0 0;
}

.list-text {
  font-family: "Roboto", system-ui, -apple-system, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #ffffff;
  font-size: 1rem;
  text-align: left;
}

.test-btn {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

.hidden {
  display: none;
}

@media screen and (max-width: 680px) {
  .list-title {
    font-size: 1rem;
  }

  .list-text {
    font-size: 0.75rem;
  }
}