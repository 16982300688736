.icon-container.list {
  margin-right: 0.5rem;
}

.svg-container-small {
  width: 28px;
  height: 28px;
}

.el-path-small {
  fill: #ffffffd9;
}

.icon-anchor:hover .el-path-small  {
  fill: var(--secondary);
}

.icon-anchor:hover .tooltip-small {
  visibility: visible;
  opacity: 1;
}

.tooltip-small {
  right: 50%;
  top: -20px;
  transform: translateX(50%);
  position: absolute;
  background-color: var(--darkoverlay);
  visibility: hidden;
  opacity: 0;
  padding: 0.3rem 0.3rem;
  border-radius: 6px;
  transition: 0.2s;
  white-space: nowrap;
  z-index: 2;
}

.tooltip-small p {
  font-family: "Roboto";
  color: #ffffff;
  font-size: 12px;
  text-align: left;
  margin: 0;
}

@media screen and (max-width: 900px) {
  /* .icon-anchor:hover .tooltip {
    display: none;
  } */
}

@media (max-width: 440px) {
  .svg-container-small {
    width: 24px;
    height: 24px;
  }

  .icon-container.list {
    margin-right: 0.25rem;
  }
  
}

@media (max-width: 400px) {
  .svg-container-small {
    width: 20px;
    height: 20px;
  }

  
}


@media screen and (max-height: 450px) and (orientation: landscape) {
  /* .svg-container {
    width: 28px;
    height: 26.5px;
  } */
}
