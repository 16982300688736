.logo-container {
  margin: 0 0 0.5rem 0;
  justify-content: flex-start;
  align-items: center;
}

.logo-header {
  width: 4rem;
  margin-bottom: 0.5rem;
}

.logo-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 2rem;
  color: var(--primarydark);
  text-align: center;
  margin: 0;
}

.logo-subtitle {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  margin: 0.5rem 0 0;
}

.logo-subtitle.light,
.logo-title.light{
  color: white;
}

.logo-subtitle.dark,
.logo-title.dark{
  color: var(--primarydark);
}


@media screen and (max-width: 680px) {
  .logo-title {
    font-size: 1.5rem;
  }

  .logo-subtitle {
    font-size: 0.875rem;
  }
}
