.switch {
  position: relative;
  display: inline-block;
  margin-left: 0.8rem;
}
.switch-input {
  display: none;
}
.switch-label {
  display: block;
  width: 24px;
  height: 14px;
  text-indent: -150%;
  clip: rect(0 0 0 0);
  color: transparent;
  user-select: none;
}
.switch-label::before,
.switch-label::after {
  content: "";
  display: block;
  position: absolute;
  cursor: pointer;
}
.switch-label::before {
  width: 24px;
  height: 14px;
  background-color: #dedede;
  border-radius: 9999em;
  -webkit-transition: background-color 0.1s ease;
  transition: background-color 0.1s ease;
}
.switch-label::after {
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--primary);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
  -webkit-transition: left 0.1s ease;
  transition: left 0.1s ease;
}
.switch-input:checked + .switch-label::before {
  background-color: var(--secondary);
}
.switch-input:checked + .switch-label::after {
  left: 12px;
}

.switch:hover .tooltip-switch {
  visibility: visible;
  opacity: 1;
}

.tooltip-switch {
  right: 116%;
  bottom: -36%;
  position: absolute;
  background-color: var(--primarydark);
  visibility: hidden;
  opacity: 0;
  padding: 0.3rem 0.3rem;
  border-radius: 6px;
  transition: 0.2s;
  white-space: nowrap;
  z-index: 2;
}

.tooltip-switch p {
  font-family: "Roboto";
  color: #ffffff;
  font-size: 12px;
  text-align: left;
  margin: 0;
}