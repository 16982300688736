.floating-container {
  position: fixed;
  top: 50%;
  right: 108px;
  transform: translateY(-50%);
  z-index: 1;
  background-color: var(--primarylight2);
  border-radius: 8px;
  min-width: 320px;
  max-width: 480px;
  padding: 1.5rem;
  box-sizing: border-box;
}

.floating-container.elements {
  width: 420px;
}

.floating-container.users {
  width: 447px;
}

.floating-container.invite-menu {
  width: 414px;
  height: 414px;
}

.triangle-mk {
  position: absolute;
  top: 50%;
  right: -9px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--primarylight2);
}

.triangle-mk.users {
  transform: translateY(-50%);
}

.triangle-mk.elements {
  transform: translateY(-360%);
}

.triangle-mk.invite {
  transform: translateY(254%);
}

.close-menu {
  position: relative;
  top: 12px;
  right: -95%;
  cursor: pointer;
}

.bar-1 {
  position: absolute;
  width: 16px;
  height: 2.7px;
  background: #ffffff;
  border-radius: 16px;
  transform: rotate(45deg);
}

.bar-2 {
  position: absolute;
  width: 16px;
  height: 2.7px;
  background: #ffffff;
  border-radius: 16px;
  transform: rotate(-45deg);
}

.settings-info {
  border: 2px solid #ffffff;
  border-radius: 10px;
  padding: 1rem 0.75rem;
  margin: 1rem 0 0.5rem 0;
}

.settings-info p {
  margin: 0;
}

.settings-instructions {
  font-family: "Roboto", system-ui, -apple-system, "Open Sans", "Helvetica Neue",
    sans-serif;
  color: #ffffff;
  font-size: 1rem;
  text-align: left;
  margin-left: 0.75rem !important;
}

.msg-text {
  margin: 1rem 0;
  font-weight: normal !important;
  font-style: italic;
}

@media screen and (max-width: 680px) {
  .floating-container,
  .floating-container.elements,
  .floating-container.users,
  .floating-container.invite-menu {
    width: 70vw;
    min-width: auto;
    max-width: none;
  }
  .settings-instructions {
    font-size: 0.875rem;
  }

  .floating-container.invite-menu {
    height: auto;
  }
}

@media (max-width: 440px) {
  .floating-container,
  .floating-container.elements {
    right: 64px;
  }
  
  .triangle-mk.elements {
    transform: translateY(-263%);
  }
  
  .triangle-mk.invite {
    transform: translateY(166%);
  }
}