.el-circle-close {
  fill: var(--error);
  transition: 0.2s;
}

.el-path-close {
  fill: #ffffff;
}

.icon-anchor:hover .tooltip-btm {
  visibility: visible;
  opacity: 1;
}

.tooltip-btm {
  top: -60px;
  position: absolute;
  background-color: var(--darkoverlay);
  visibility: hidden;
  opacity: 0;
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  transition: 0.2s;
  white-space: nowrap;
}

.tooltip-btm p {
  font-family: "Roboto";
  color: #ffffff;
  font-size: 1rem;
  text-align: left;
  margin: 0;
}

.triangle-btm {
  position: absolute;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--darkoverlay);
  right: 50%;
  transform: rotateZ(90deg) translateY(-30%);
  bottom: -15px;
}
