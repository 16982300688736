.app {
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.epfl-logo {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 1;
  width: 80px;
}
