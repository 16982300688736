.joystick-container {
  position: fixed;
  width: 120px;
  height: 120px;
  pointer-events: none;
  z-index: 1;
  top: auto;
  bottom: 20px;
  left: 20px;
  touch-action: manipulation;
}

.joystick-wrapper1 {
  pointer-events: auto;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: transparent;
  width: 120px;
  height: 120px;
  z-index: 12;
  touch-action: manipulation;
}