.room-card{
  width: 160px;
  height: 141.9px;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
}

.room-card:hover {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
}

.thumb-img {
  border-radius: 8px 8px 0 0;
  width: 100%;
}

.room-card-name {
  color: var(--primary);
  font-size: 14px;
  font-family: "Roboto";
  margin: 0.25rem 0.4rem;
}
