.submit-btn {
  margin-top: 0.5rem;
}
.submit-btn.margin-right {
  margin-right: 0.75rem;
}

.slide-options {
  justify-content:flex-start;
  align-items: center;
  margin: 0 0 0rem 0;
}

.settings-text {
  font-family: "Roboto";
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  margin: 0 0 0.5rem 0;
  width: 80%;
}

.settings-options {
  justify-content: space-between;
  margin: 0 0 1rem 0;
}

.settings-input-container {
  justify-content: space-between;
  align-items: center;
  width: 240px;
  margin: 0 0;
}

.settings-input {
  margin: 0;
  padding: 8px;
  border: none;
  border-radius: 10px;
  resize: none;
  outline: none;
  min-width: 150px;
  max-width: 200px;
  font-family: "Roboto", system-ui, -apple-system, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: var(--primary);
  font-size: 14px;
}

.element-name {
  font-family: "Roboto";
  font-size: 0.75rem;
  color: white;
}

.elements-btn-row {
  align-items: center;
  justify-content: flex-end;
}